import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/page.js";
import { useStaticQuery, graphql } from "gatsby";
import { rgba } from "polished";
import { Wrapper, Container, Row } from "../components/ui/Grid";
import Article, { Separator } from "../components/ui/Article";
import theme from "../theme";
import Values from "../components/sections/Values";
import TOC from "../components/sections/TableOfContents";
import Sticky from "react-stickynode";
import Super from "../components/ui/Super";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Separator mdxType="Separator" />
    <Container size="md" mdxType="Container">
      <Article mdxType="Article">
        <p>{`Conscious Capital Fund (CCF) grew out of the `}<a parentName="p" {...{
            "href": "https://ctr.com/",
            "target": "_self",
            "rel": "nofollow"
          }}>{`Conscious Thought Revolution (CTR)`}</a>{` that was founded in 2014, when two successful entrepreneurs, `}<a parentName="p" {...{
            "href": "/team/gordy-bal/"
          }}>{`Gordy Bal`}</a>{` and `}<a parentName="p" {...{
            "href": "/team/aaron-samsonoff/"
          }}>{`Aaron Samsonoff`}</a>{`, decided to pool their talents in order to focus on Conscious Capitalism.`}</p>
        <p>{`They made a commitment to raise consciousness of global challenges, and invest in companies who were offering solutions.`}</p>
        <p>{`After attracting other like-minded innovators, the group evolved into a team dedicated to building a venture capital fund focusing on conscious capitalism.`}</p>
        <p>{`CCF was `}<a parentName="p" {...{
            "href": "/about/"
          }}>{`launched in 2018`}</a>{`. It’s attracting the interest of investors who want to put their capital to work solving worldwide poverty, famine, hunger, environmental disasters and the suffering caused by war.`}</p>
        <p>{`The CCF philosophy underpins a belief that connecting impact investors with companies who are committed to conscious capitalism will help to resolve the world’s problems. The roots of our philosophy are:`}</p>
      </Article>
    </Container>
    <Values mdxType="Values" />
    <Container size="md" mdxType="Container">
      <Article id="ctr-values-and-principles" mdxType="Article">
        <h2 {...{
          "id": "ctr-values-and-principles",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#ctr-values-and-principles",
            "aria-label": "ctr values and principles permalink",
            "className": "anchor-link before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`CTR Values and Principles`}</h2>
        <p>{`Our parent organization, CTR is value-driven, and those values and principles guide our CCF initiatives:`}</p>
        <p>{`Integrity | Social Responsibility | Ethical Behaviour | Accountability`}</p>
        <p>{`As we work with companies who are committed to conscious capitalism, these are our guiding principles:`}</p>
        <ul>
          <li parentName="ul">{`Increased human consciousness`}</li>
          <li parentName="ul">{`Socially-responsible investment`}</li>
          <li parentName="ul">{`Entrepreneurship`}</li>
          <li parentName="ul">{`Win-Win`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://sustainabledevelopment.un.org/?menu=1300",
              "target": "_self",
              "rel": "nofollow"
            }}>{`Sustainable Development Goals`}</a></li>
        </ul>
      </Article>
    </Container>
    <Sticky innerZ={900} top={80} bottomBoundary='#footer' mdxType="Sticky">
  <TOC mdxType="TOC" />
    </Sticky>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "32.818532818532816%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA/0lEQVQY04WQzUoCURiG53q8hK7AG2gRtHJZqy7ARUaLBNuF/UFCSAXuRDA1NBcS7cJUBmowmj9T58w55zvfOTMlJ8r1KLy7l4f3xxAgVwuF+pdkVLAQgKNEtbSM1SRwDAnMptQcOh/WxHPmrj33nYAzsQbmDLXWndZwZ+uqWX2plJ8zm5cbqYOj/RolsC5ZKGv0/tTtnxVaY9PN7t5tp0+ye5WH+uvfBCoSYWAYLxb3hzft23azPijmG72OWTrt6p/v6+LjqG9HUWwkdxZa6/NC+Th3QYi03vxgxiYe+fJDc2D7LpGokmtzKTHynOnn2ItULFEBRxoCCTgKtTz8F27CeQu8R33qAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Network Effect",
          "title": "Network Effect",
          "src": "/static/26e6d4183cbe618f14a68d42ee7b4bbf/e3189/network_effect.png",
          "srcSet": ["/static/26e6d4183cbe618f14a68d42ee7b4bbf/a2ead/network_effect.png 259w", "/static/26e6d4183cbe618f14a68d42ee7b4bbf/6b9fd/network_effect.png 518w", "/static/26e6d4183cbe618f14a68d42ee7b4bbf/e3189/network_effect.png 1035w"],
          "sizes": "(max-width: 1035px) 100vw, 1035px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <Container size="md" mdxType="Container">
      <Article id="the-problem-that-ccf-can-resolve" mdxType="Article">
        <h2 {...{
          "id": "the-problem-that-ccf-can-resolve",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#the-problem-that-ccf-can-resolve",
            "aria-label": "the problem that ccf can resolve permalink",
            "className": "anchor-link before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`The Problem That CCF Can Resolve`}</h2>
        <p>{`There are four dimensions to the problem that CCF is targeting.`}</p>
        <h4 {...{
          "id": "1-global-challenges-exist",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#1-global-challenges-exist",
            "aria-label": "1 global challenges exist permalink",
            "className": "anchor-link before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`1. Global Challenges Exist`}</h4>
        <p>
  According to the World Bank, <a href="http://povertydata.worldbank.org/poverty/home/">736 million people</a> live in extreme poverty, surviving on less than $1.90 <Super href={true} content="http://povertydata.worldbank.org/poverty/home/" mdxType="Super">1</Super>, and with no hope of acquiring the assets that would lead to financial inclusion and a voice in decisions about their own future.
        </p>
        <p>
  Oxfam reports that millions of people are facing starvation across East
  Africa, South Sudan and Yemen. Oxfam is on the ground providing food, clean
  water and sanitation.{" "}
  <Super href={true} content='https://www.oxfam.org/en/emergencies/famine-and-hunger-crisis' mdxType="Super">
    2
  </Super>
        </p>
        <p>{`National Graphic reports on the devastating effects of pollution on our environment, including water contamination and air pollution caused by burning fossil fuels. `}<Super href={true} content="https://www.nationalgeographic.org/encyclopedia/pollution/" mdxType="Super">{`3`}</Super></p>
        <h4 {...{
          "id": "2-lack-of-consciousness-of-worldwide-suffering",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#2-lack-of-consciousness-of-worldwide-suffering",
            "aria-label": "2 lack of consciousness of worldwide suffering permalink",
            "className": "anchor-link before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`2. Lack of Consciousness of Worldwide Suffering`}</h4>
        <p>{`Most people lack consciousness of the magnitude and scope of suffering across the globe.
There is denial over the needs for climate rehabilitation, to assist refugees, growing suicidal tendencies among youth and the horrors of war.`}</p>
        <p>{`The majority of us turn away when there is discussion about the plight of others…we literally switch off when television channels try to show the extent of human misery.`}</p>
        <h4 {...{
          "id": "3-inadequate-funding-for-innovative-solutions",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#3-inadequate-funding-for-innovative-solutions",
            "aria-label": "3 inadequate funding for innovative solutions permalink",
            "className": "anchor-link before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`3. Inadequate Funding for Innovative Solutions`}</h4>
        <p>{`Some entrepreneurs and corporations are conscious of the extent of human need, but haven’t found a way of earning the profit shareholders expect while investing in conscious capitalism.`}</p>
        <p>{`They have solutions, and are willing and able to put their time and energy into making a difference.`}</p>
        <p>{`However, they often don’t have the financial resources to solve the challenges, and don’t know how to access funds.`}</p>
        <h4 {...{
          "id": "4-investors-are-not-connected-to-the-innovators",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#4-investors-are-not-connected-to-the-innovators",
            "aria-label": "4 investors are not connected to the innovators permalink",
            "className": "anchor-link before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`4. Investors are Not Connected to the Innovators`}</h4>
        <p>{`Many investors have not been introduced to the philosophy of conscious capitalism and don’t understand the positive impact that funding can have on global challenges.`}</p>
        <p>{`Impact investors need help in finding conscious capital organizations in which to invest.`}</p>
        <p>{`Investors may not be familiar with the fact that profit is one of the pillars of conscious capital investing, and there is significant ROI.`}</p>
      </Article>
    </Container>
    <Wrapper bgColor={rgba(theme.color.grey, 0.04)} mdxType="Wrapper">
      <Container size="md" mdxType="Container">
        <Article id="the-ccf-solution" bgColor={rgba(theme.color.grey, 0.005)} mdxType="Article">
          <h2 {...{
            "id": "the-ccf-solution",
            "style": {
              "position": "relative"
            }
          }}><a parentName="h2" {...{
              "href": "#the-ccf-solution",
              "aria-label": "the ccf solution permalink",
              "className": "anchor-link before"
            }}><svg parentName="a" {...{
                "aria-hidden": "true",
                "focusable": "false",
                "height": "16",
                "version": "1.1",
                "viewBox": "0 0 16 16",
                "width": "16"
              }}><path parentName="svg" {...{
                  "fillRule": "evenodd",
                  "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
                }}></path></svg></a>{`The CCF Solution`}</h2>
          <p>{`Margaret Mead, the renowned anthropologist, is reported to have said:`}</p>
          <blockquote>
            <p parentName="blockquote">{`"Never doubt that a small group of thoughtful, committed, citizens can change the world. Indeed, it is the only thing that ever has.”`}</p>
          </blockquote>
          <p>{`The CCF is one of those “thoughtful, committed groups”, and we are determined to address the problems we’ve outlined in the following ways:`}</p>
          <h4 {...{
            "id": "1-global-challenges-exist--the-ccf-solution",
            "style": {
              "position": "relative"
            }
          }}><a parentName="h4" {...{
              "href": "#1-global-challenges-exist--the-ccf-solution",
              "aria-label": "1 global challenges exist  the ccf solution permalink",
              "className": "anchor-link before"
            }}><svg parentName="a" {...{
                "aria-hidden": "true",
                "focusable": "false",
                "height": "16",
                "version": "1.1",
                "viewBox": "0 0 16 16",
                "width": "16"
              }}><path parentName="svg" {...{
                  "fillRule": "evenodd",
                  "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
                }}></path></svg></a>{`1. Global Challenges Exist – The CCF Solution`}</h4>
          <p>{`The CCF team recognizes that there are many global challenges and we are committed to making a difference.`}</p>
          <p>{`Using second order thinking that helps us look at things in a different way, CCF is studying the causes of war, poverty, famine and disease in a way the ensures our strategies are informed.`}</p>
          <p>{`We are seeking to connect ethical, impact investors with creative innovators whose consciousness of the issues is leading them towards answers.`}</p>
          <h4 {...{
            "id": "2-lack-of-consciousness-of-worldwide-suffering--the-ccf-solution",
            "style": {
              "position": "relative"
            }
          }}><a parentName="h4" {...{
              "href": "#2-lack-of-consciousness-of-worldwide-suffering--the-ccf-solution",
              "aria-label": "2 lack of consciousness of worldwide suffering  the ccf solution permalink",
              "className": "anchor-link before"
            }}><svg parentName="a" {...{
                "aria-hidden": "true",
                "focusable": "false",
                "height": "16",
                "version": "1.1",
                "viewBox": "0 0 16 16",
                "width": "16"
              }}><path parentName="svg" {...{
                  "fillRule": "evenodd",
                  "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
                }}></path></svg></a>{`2. Lack of Consciousness of Worldwide Suffering – The CCF Solution`}</h4>
          <p>{`While others turn away, our CCF group does not. We move towards the issues, rather than distancing ourselves, as others do.
The CCF team is devoting time and effort to raising our own consciousness of global challenges, so that we can help others become more aware.`}</p>
          <p>{`We are thinking ahead, with a 50-year plan to raise consciousness to a point where investors are interacting directly with innovators in humanity’s best interests.`}</p>
          <h4 {...{
            "id": "3-inadequate-funding-for-innovative-solutions--the-ccf-solution",
            "style": {
              "position": "relative"
            }
          }}><a parentName="h4" {...{
              "href": "#3-inadequate-funding-for-innovative-solutions--the-ccf-solution",
              "aria-label": "3 inadequate funding for innovative solutions  the ccf solution permalink",
              "className": "anchor-link before"
            }}><svg parentName="a" {...{
                "aria-hidden": "true",
                "focusable": "false",
                "height": "16",
                "version": "1.1",
                "viewBox": "0 0 16 16",
                "width": "16"
              }}><path parentName="svg" {...{
                  "fillRule": "evenodd",
                  "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
                }}></path></svg></a>{`3. Inadequate Funding for Innovative Solutions – The CCF Solution`}</h4>
          <p>{`CCF is working to convince investors, who have the best of intentions, but who feel they have to comply with shareholder demand for profits, that companies who focus on conscious capitalism are shown to produce higher-than-average returns on investment.`}</p>
          <p>{`The CCF team is actively seeking out individual investors, by highlighting the commitment and expertise of the CCF leadership, and providing assurances that there will be secure and conscious stewardship of their capital.
CCF is attracting investors by increasing consciousness of worldwide challenges and making investors aware of the opportunity to achieve positive change.`}</p>
          <h4 {...{
            "id": "4-investors-are-not-connected-to-the-innovators--the-ccf-solution",
            "style": {
              "position": "relative"
            }
          }}><a parentName="h4" {...{
              "href": "#4-investors-are-not-connected-to-the-innovators--the-ccf-solution",
              "aria-label": "4 investors are not connected to the innovators  the ccf solution permalink",
              "className": "anchor-link before"
            }}><svg parentName="a" {...{
                "aria-hidden": "true",
                "focusable": "false",
                "height": "16",
                "version": "1.1",
                "viewBox": "0 0 16 16",
                "width": "16"
              }}><path parentName="svg" {...{
                  "fillRule": "evenodd",
                  "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
                }}></path></svg></a>{`4. Investors are Not Connected to the Innovators – The CCF Solution`}</h4>
          <p>{`CCF is searching for companies who need funding for innovation, and connecting them with investors who are becoming more conscious of the global challenges.`}</p>
          <p>{`Portfolio companies have the shoulders of giants to stand on, and unprecedented access to the talent and skills of the CCF team.`}</p>
          <p>{`As the CCF network of investors and innovators grows, we will be able to use warm connections to cement productive partnerships.`}</p>
          <p>{`We are motivated by passion and purpose to see a transformed world and we are looking for the potential to impact a billion lives or more. The CCF team has a proven ability to execute, with a strong track record based on measurable results. We hold a futurist perspective because we have shown that we can read between the lines and find success where others only see barriers. The cause is compelling our network to grow and include more.We are using the synergies created by conscious capitalism to drive a cultural shift.`}</p>
        </Article>
      </Container>
    </Wrapper>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "32.818532818532816%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAHABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAECBP/EABYBAQEBAAAAAAAAAAAAAAAAAAEAAv/aAAwDAQACEAMQAAABzQNQaP/EABgQAAMBAQAAAAAAAAAAAAAAAAABAhIT/9oACAEBAAEFAlRVvPQ//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8BR//EABUQAQEAAAAAAAAAAAAAAAAAABAh/9oACAEBAAY/Aq//xAAZEAACAwEAAAAAAAAAAAAAAAABEQAhQXH/2gAIAQEAAT8hMQC67sIJMXLLJ//aAAwDAQACAAMAAAAQh8//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPxBX/8QAFhEBAQEAAAAAAAAAAAAAAAAAABFB/9oACAECAQE/ENQ//8QAGxABAAICAwAAAAAAAAAAAAAAAQARIVExQYH/2gAIAQEAAT8Q4plHbxGJpYbbixkoKa7dz//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Double RIO",
          "title": "Double RIO",
          "src": "/static/488f217dd87c716574047b10f3b89756/af659/double_roi.jpg",
          "srcSet": ["/static/488f217dd87c716574047b10f3b89756/8356d/double_roi.jpg 259w", "/static/488f217dd87c716574047b10f3b89756/bc760/double_roi.jpg 518w", "/static/488f217dd87c716574047b10f3b89756/af659/double_roi.jpg 1035w", "/static/488f217dd87c716574047b10f3b89756/69128/double_roi.jpg 1036w"],
          "sizes": "(max-width: 1035px) 100vw, 1035px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <Container size="md" mdxType="Container">
      <Article id="ccf-vision-and-mission" mdxType="Article">
        <h2 {...{
          "id": "ccf-vision-and-mission",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#ccf-vision-and-mission",
            "aria-label": "ccf vision and mission permalink",
            "className": "anchor-link before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`CCF Vision and Mission`}</h2>
        <p>{`Our vision is a community of socially-responsible, impact investors who:`}</p>
        <ul>
          <li parentName="ul">{`are committed to consciousness of the human condition;`}</li>
          <li parentName="ul">{`fund innovators who are addressing worldwide problems;`}</li>
          <li parentName="ul">{`benefit from a triple bottom line that ensures profit by investing in people and the planet;`}</li>
          <li parentName="ul">{`help to ensure a kinder, healthier, more secure population, that thrives in an equitable, sustainable and nurturing global environment and;`}</li>
          <li parentName="ul">{`participate in a network of like-minded investors to exchange ideas.`}</li>
        </ul>
        <p>{`The mission for CCF is to build a venture capital fund that will support a flourishing eco-system of entrepreneurs, innovators and investors who are committed to raising consciousness of the human condition, in order to effect change.`}</p>
        <p>{`We are vision- and mission-focused and destined to execute, in order to address all the dimensions of the problem.`}</p>
      </Article>
    </Container>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "32.818532818532816%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAHABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIB/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQX/2gAMAwEAAhADEAAAAa0alAP/xAAWEAEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQEAAQUC1K1//8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8BiP/EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/AUf/xAAUEAEAAAAAAAAAAAAAAAAAAAAQ/9oACAEBAAY/An//xAAZEAACAwEAAAAAAAAAAAAAAAAAEQExUXH/2gAIAQEAAT8hfSS2Jw//2gAMAwEAAgADAAAAEHPP/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/ELDL/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAECAQE/EELF/8QAGRABAQEBAQEAAAAAAAAAAAAAAREAQTFR/9oACAEBAAE/ECvrKRKHF1e18l3/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Hudson Bay",
          "title": "Hudson Bay",
          "src": "/static/654dae8f1b4162054e625e7a8ed259b4/af659/view.jpg",
          "srcSet": ["/static/654dae8f1b4162054e625e7a8ed259b4/8356d/view.jpg 259w", "/static/654dae8f1b4162054e625e7a8ed259b4/bc760/view.jpg 518w", "/static/654dae8f1b4162054e625e7a8ed259b4/af659/view.jpg 1035w"],
          "sizes": "(max-width: 1035px) 100vw, 1035px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <Container size="md" mdxType="Container">
      <Article id="how-the-ccf-vision-affects-execution" mdxType="Article">
        <h2 {...{
          "id": "how-the-ccf-vision-affects-execution",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#how-the-ccf-vision-affects-execution",
            "aria-label": "how the ccf vision affects execution permalink",
            "className": "anchor-link before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`How the CCF Vision Affects Execution`}</h2>
        <p>{`We have a brand, a vision, a cause – and people on all sides of the spectrum want to be a part of CCF. Without even having to introduce ourselves we have strangers lining up to join the cause.`}</p>
        <p>{`It’s not just numbers and percentages to us, it’s about unlocking the hidden value possessed by the founders and businesses we include in our portfolio.`}</p>
        <p>{`We choose to invest in them in order to go beyond what is imaginable today.`}</p>
        <h3 {...{
          "id": "investment-is-de-risked-in-the-ccf-execution-strategy",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#investment-is-de-risked-in-the-ccf-execution-strategy",
            "aria-label": "investment is de risked in the ccf execution strategy permalink",
            "className": "anchor-link before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Investment is De-risked in the CCF Execution Strategy`}</h3>
        <p>{`As part of our execution, we mitigate negating factors or obstacles facing companies that are in the CCF portfolio, to help them overcome barriers to our mutual success.`}</p>
        <p>{`A key aspect to the de-risking is the fact that deals are often vetted cursorily, prior to the funds due diligence process, through the members and mechanisms of the Conscious Capital network. The network itself allows for continued integration and development of each business.`}</p>
        <p>{`During our screening process for de-risking we look for companies which have the following characteristics:`}</p>
        <ul>
          <li parentName="ul">{`A founding team of executors (typically 3, more than 1, no more than 4) with a previous history/track record of success, with a well rounded set of skills, members that symbiotically compliment each others weaknesses with their own talents and areas of executionairy expertise`}</li>
          <li parentName="ul">{`A collection of advisors that suitably are capable of assisting the team in the growing pains of transitioning a business from the mode and mentality of starting up`}</li>
          <li parentName="ul">{`A product or service that measurably solves a truly painful problem in an exponentially superior fashion, versus an incremental improvement of an annoyance the targeted customer has`}</li>
          <li parentName="ul">{`A well thought out business model, with a path and plan for growth, detailing how they will market their product/service, an explanation of the opportunities size, scale, magnitude, and timing, including their future imagined.`}</li>
          <li parentName="ul">{`Existing in a market with competitors who have an attractive valuation or exit potential, but lack the unique value proposition that our portfolio company has, with enough differentiation and defensibility to attract and retain a large percentage of market share`}</li>
          <li parentName="ul">{`In possession of or the ability to manufacture catalysts for valuation growth, for example innovative technology.`}</li>
        </ul>
        <p>{`We actively look to fuel our own feedback loops, leveraging frameworks such as `}<a parentName="p" {...{
            "href": "https://www.globalreporting.org/standards/",
            "target": "_self",
            "rel": "nofollow"
          }}>{`GRI Reporting Standards`}</a>{` and the `}<a parentName="p" {...{
            "href": "https://iris.thegiin.org/metrics/",
            "target": "_self",
            "rel": "nofollow"
          }}>{`IRIS metrics`}</a>{`.`}</p>
        <p>{`By measuring our current impact we can utilize historic and current data gathered, to further our portfolio’s reach and improve each company’s ability to further service the mission of expanding consciousness.`}</p>
        <p>{`Our portfolio companies are able to assess their own content, services, products and experiences to improve how investment impact is measured. This results in a risk-adjusted return, where the risk is lower and the returns and impact are higher.`}</p>
      </Article>
    </Container>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "32.818532818532816%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAHABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAEDBP/EABYBAQEBAAAAAAAAAAAAAAAAAAMABP/aAAwDAQACEAMQAAABw2mqkMv/xAAWEAADAAAAAAAAAAAAAAAAAAABECH/2gAIAQEAAQUCFf8A/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8BJ//EABQQAQAAAAAAAAAAAAAAAAAAABD/2gAIAQEABj8Cf//EABYQAQEBAAAAAAAAAAAAAAAAAAEAEP/aAAgBAQABPyFojP/aAAwDAQACAAMAAAAQD8//xAAWEQEBAQAAAAAAAAAAAAAAAAABACH/2gAIAQMBAT8QHYv/xAAWEQEBAQAAAAAAAAAAAAAAAAAAASH/2gAIAQIBAT8QvUf/xAAYEAEAAwEAAAAAAAAAAAAAAAABABEhMf/aAAgBAQABPxABS52WXeQUKn//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Double RIO",
          "title": "Double RIO",
          "src": "/static/28291af8f6b8c0c0df21915a4b3cf1c6/af659/community.jpg",
          "srcSet": ["/static/28291af8f6b8c0c0df21915a4b3cf1c6/8356d/community.jpg 259w", "/static/28291af8f6b8c0c0df21915a4b3cf1c6/bc760/community.jpg 518w", "/static/28291af8f6b8c0c0df21915a4b3cf1c6/af659/community.jpg 1035w"],
          "sizes": "(max-width: 1035px) 100vw, 1035px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <Container size="md" mdxType="Container">
      <Article id="what-we-mean-by-proximity" mdxType="Article">
        <h2 {...{
          "id": "what-we-mean-by-proximity",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#what-we-mean-by-proximity",
            "aria-label": "what we mean by proximity permalink",
            "className": "anchor-link before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`What We Mean by Proximity`}</h2>
        <h3 {...{
          "id": "our-network-gives-us-access-to-opportunities",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#our-network-gives-us-access-to-opportunities",
            "aria-label": "our network gives us access to opportunities permalink",
            "className": "anchor-link before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Our network gives us access to opportunities`}</h3>
        <p>{`We are well-connected with a diverse network of influencers, businesses and investors who are open to opportunities at an earlier stage of development.`}</p>
        <p>{`As these connections and relationships grow, we are very confident that CCF will be seen as a leader in the Conscious Capital space.`}</p>
        <p>{`We have important momentum in the Venture Capital and Investments sector, with the growing awareness of global influence, the ripple effects of multinational business decisions, and a focus on expanding consciousness. More so now than ever the timing is right, people crave for change to be affected.`}</p>
        <p>{`In the population where $68T is changing generational hands, where the world view is being opened up and the state of the human condition is being exposed to these new investors, we’ve seen a shift in the desire to leave a legacy of impact, beyond just an inheritance.`}</p>
        <p>{`Given the mindset of these enlightened investors, our approach is to pursue opportunities aligned with Conscious Capital (and Conscious Capitalism) to make an impact and reshape the world for a better future.`}</p>
        <p>{`Misaligned missions and purposes are non-starters with the “new conscious” companies. These companies want to “Do Good” and experience what results from that.`}</p>
        <blockquote>
          <p parentName="blockquote">{`Conscious companies treat their stakeholders better. As a consequence, their suppliers are happier to do business with them. Employees are more engaged, productive, and likely to stay. These companies are more welcome in their communities and their customers are more satisfied and loyal. The most conscious companies give more, and they get more in return. The inescapable conclusion: “It pays to care, widely and deeply.” `}<Super content="Companies that Practice “Conscious Capitalism” Perform 10x Better. Tony Schwartz. 2013. <a href='https://hbr.org/2013/04/companies-that-practice-conscious-capitalism-perform'>(Link)</a>" mdxType="Super">{`4`}</Super></p>
        </blockquote>
        <p>{`CCF aims to align the capital directly with the vision, mission and purpose of each company in which we invest. In this way, we create a symbiotic, advantageous relationship our investors and investees find more than enticing. Conscious companies and investors resonate with the vision of CCF.`}</p>
        <h3 {...{
          "id": "access-and-proximity-give-us-the-advantage-of-better-terms",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#access-and-proximity-give-us-the-advantage-of-better-terms",
            "aria-label": "access and proximity give us the advantage of better terms permalink",
            "className": "anchor-link before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Access and proximity give us the advantage of better terms.`}</h3>
        <p>{`We’re able to get into the investment opportunity sooner than other funds because we have warm market connections. We are prepared to execute quickly with our developed methods of discerning decisions from our proprietary and thorough – yet expedited – due diligence techniques.`}</p>
        <p>{`This results in an earlier stage investment, typically at a lower valuation with favorable terms.`}</p>
        <blockquote>
          <p parentName="blockquote">{`We’re there sooner, more aligned and bring more than just money to the table.`}</p>
        </blockquote>
        <p>{`As the status of the VC fund rises and we show better results, we receive increasingly favorable terms all due to the result from the network effect.`}</p>
        <p>{`We also provide companies that form the portfolio of CCF with a utility marriage to help them solve their problems.
However, Venture Capital and investing is more than a marriage. You cannot fire your VC, so selecting who makes investments and who you make investments in cannot be understated.`}</p>
        <p>{`With our focus and continued support, CCF founders understand that our alignment and goals will never conflict with our investors. To the contrary, our continued efforts to push forward with a vision compatible with theirs creates an expanded consciousness.`}</p>
        <p>{`The alignment with the intention that there is more to be achieved beyond profit, and through that synergy with capital investment, brings in opportunities that would otherwise be impossible or simply overlooked.`}</p>
      </Article>
    </Container>
    <Container size="md" mdxType="Container">
      <Article id="why-we-believe-in-the-triple-bottom-line" mdxType="Article">
        <h2 {...{
          "id": "why-we-believe-in-the-triple-bottom-line",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#why-we-believe-in-the-triple-bottom-line",
            "aria-label": "why we believe in the triple bottom line permalink",
            "className": "anchor-link before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Why We Believe in the Triple Bottom Line`}</h2>
        <h4 {...{
          "id": "people",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#people",
            "aria-label": "people permalink",
            "className": "anchor-link before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`People.`}</h4>
        <h4 {...{
          "id": "planet",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#planet",
            "aria-label": "planet permalink",
            "className": "anchor-link before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Planet.`}</h4>
        <h4 {...{
          "id": "profit",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#profit",
            "aria-label": "profit permalink",
            "className": "anchor-link before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Profit.`}</h4>
        <p>{`These are the three pillars of the Triple Bottom Line - a term first introduced by John Elkington `}<Super href={true} content="https://hbr.org/2018/06/25-years-ago-i-coined-the-phrase-triple-bottom-line-heres-why-im-giving-up-on-it" mdxType="Super">{`5`}</Super>{` in the 1990s, but now being re-invigorated by the Conscious Capitalism Field Guide. `}<Super href={true} content="https://hbr.org/2018/06/25-years-ago-i-coined-the-phrase-triple-bottom-line-heres-why-im-giving-up-on-it" mdxType="Super">{`6`}</Super></p>
        <p>{`We believe that investments in CCF will benefit the peoples of the world and will lead to a healthier, safer planet, while returning profits that can then be re-invested for further good.`}</p>
        <p>{`We invest in organizations that focus on people, planet and profit.`}</p>
        <p>{`ROI isn’t just Return on Investment, but also Return on Impact.`}</p>
        <h3 {...{
          "id": "people-1",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#people-1",
            "aria-label": "people 1 permalink",
            "className": "anchor-link before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`People`}</h3>
        <p>{`With a focus on people we believe with an established baseline metrics you can get a real sense of the DNA of the company, a few examples would include Sexual Harassment Policy, Anti-Discrimination Policy, Diverse Representation Policy with attention to Charitable Practices and Social Impact Objectives. This is by no means restrictive to just the employees so for customers we ensure that the companies have Client Protection Policy, Client Feedback System and Client Complaint Tracking System. As well for any of our investments we track and monitor Percent Female Ownership, Percent Minority/Previously Excluded Ownership, Gender Wage Equity, Women’s Career Advancement Initiative, Fair Dismissal Policy and Fair Hiring/Recruiting Policy. And to ensure compliance with a Forced Labor Policy.`}</p>
        <p>{`If you want to explore some of our metrics with more detail you can visit `}<a parentName="p" {...{
            "href": "https://iris.thegiin.org/metrics",
            "target": "_self",
            "rel": "nofollow"
          }}>{`this link`}</a>{` for more information.`}</p>
        <h3 {...{
          "id": "planet-1",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#planet-1",
            "aria-label": "planet 1 permalink",
            "className": "anchor-link before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Planet`}</h3>
        <p>{`We can only affect the world with a focus on rebuilding the planet and shaping the way we interact with the planet for the future. For example we want all of our companies to report these metrics Climate Resilience Strategy, Hazardous Waste Avoided, Energy Consumption/Savings/Capacity, Biodegradable Materials and Greenhouse Gas Emissions`}</p>
        <p>{`If you want to explore some of our metrics with more detail you can visit `}<a parentName="p" {...{
            "href": "https://iris.thegiin.org/metrics",
            "target": "_self",
            "rel": "nofollow"
          }}>{`this link`}</a>{` for more information.`}</p>
        <h3 {...{
          "id": "profit-1",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#profit-1",
            "aria-label": "profit 1 permalink",
            "className": "anchor-link before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Profit`}</h3>
        <p>{`Conscious Capitalism yields better results.`}</p>
        <p>{`According to a Harvard Business Review `}<Super href={true} content="https://hbr.org/2013/04/companies-that-practice-conscious-capitalism-perform" mdxType="Super">{`7`}</Super>{` article, companies that practice Conscious Capitalism produce 10x better results.`}</p>
        <p>{`Conscious capital is financial capital that is raised, built, managed and deployed strategically, for the purpose of raising human consciousness and expanding the scope of activities that can measurably improve world conditions.`}</p>
        <p>{`We have a triple bottom line which means consciousness is key to every business we invest in. What does that mean? It means leaders innovating in management, a focus on employees, a focus on the planet, people, and purpose. We know that with this rule others have experienced up to 14x increased returns in investment and an immeasurable impact in the world `}<Super content="Sisodia, Rajendra and Sheth, Jagdish N. Firms of  Endearment. 2nd ed., Pearson FT Press, 2014" mdxType="Super">{`8`}</Super>{`.`}</p>
        <h4 {...{
          "id": "the-profit-is-measured",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#the-profit-is-measured",
            "aria-label": "the profit is measured permalink",
            "className": "anchor-link before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`The profit is measured`}</h4>
        <p>{`The criteria for measuring Return on Investment for each company in the CCF include things like Return on Assets (ROA), Return on Equity (ROE) and Revenue Growth, EBITDA.`}</p>
        <p>{`If you want to explore some of our metrics with more detail you can visit `}<a parentName="p" {...{
            "href": "https://iris.thegiin.org/metrics",
            "target": "_self",
            "rel": "nofollow"
          }}>{`this link`}</a>{` for more information.`}</p>
        <p>{`We see data as the powerhouse of the future. With it we are able to create technology and aggregation services to ensure there is a mutually beneficial comprehension of what capital is doing, and what companies are doing to impact the world.`}</p>
        <p>{`A feedback loop is created.`}</p>
        <p>{`This results in creating accountability and transparency which are foundational cornerstones to Conscious Capitalism, and lead to an increase in the Triple Bottom Line.`}</p>
      </Article>
    </Container>
    <Container size="md" mdxType="Container">
      <Article id="our-commitment-to-building-a-conscious-capital-community" mdxType="Article">
        <h2 {...{
          "id": "our-commitment-to-building-a-conscious-capital-community",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#our-commitment-to-building-a-conscious-capital-community",
            "aria-label": "our commitment to building a conscious capital community permalink",
            "className": "anchor-link before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Our Commitment to Building a Conscious Capital Community`}</h2>
        <p>{`Our parent company, CTR, is building a community that will include annual events, online sessions with guest speakers, forums and connections, an educational platform, as well as a myriad other things which supplement a community and allow it to flourish.`}</p>
        <p>{`The community will support both sides: the limited partners investing or interested in investing, as well as the founders and companies we invest into, but we intend on extending invitations out to curated guests as well as the unknown.`}</p>
        <p>{`The community will open the doors for investors to get access to a much larger network of individuals looking to impact the world.`}</p>
        <p>{`As well, it will provide the opportunity to pool capital together with other individuals looking to make an impact in the world.`}</p>
        <p>{`A struggle for the generational wealth changing hands, is that those investors want to make impact a focus without giving up yield, but there are few opportunities or mentors to direct it.`}</p>
        <p>{`Often for those interested in investing in conscious companies there aren’t enough deals available out there. In many cases the requirements to assess and curate is just not feasible unless it’s a full-time endeavor.`}</p>
        <p>{`This opens the doors for CTR to exist as a pre-eminent authority in the Conscious Capital space, which would allow people seeking out the impact with their capital or skills to have a community where the troubles are handled and the framework is set.`}</p>
        <p>{`Our goal is to foster a thriving community of companies and individuals who are looking to create the impact and live fulfilled, understanding that the problems we face today often need a different approach to a solution.`}</p>
        <p>{`This will provide an opportunity for limited partners and companies to share super powers, whether it be capital or skill, with a larger community.`}</p>
        <p>{`If you would like further information about CCF, please `}<a parentName="p" {...{
            "href": "#footer"
          }}>{`contact us`}</a>{`.`}</p>
      </Article>
    </Container>
    <div id='footer' />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      